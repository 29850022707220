/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types'
import Link from 'next/link'
import Typography from '@material-ui/core/Typography'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import CtflAssetContainer from '@components/ctfl-asset/ctfl-asset.container'
import renderLink from './render-link'
import RenderEmbeddedEntry from './render-embedded-entry'
import RenderInlineEmbeddedEntry from './render-inline-embedded-entry'
import getTypeFromLinks from './utils/getTypeFromLinks'
import { RichText } from './renderers.styles'

const RendererRichText = ({ json, links }) => {
  const typesFromLinks = links ? getTypeFromLinks(links) : {}

  const options = {
    renderNode: {
      [BLOCKS.HEADING_3]: node => (
        <Typography variant="h3" gutterBottom>{node.content[0].value}</Typography>
      ),
      [BLOCKS.HEADING_4]: node => (
        <Typography variant="h4" gutterBottom>{node.content[0].value}</Typography>
      ),
      [BLOCKS.HEADING_5]: node => (
        <Typography variant="h5" gutterBottom>{node.content[0].value}</Typography>
      ),
      [BLOCKS.HEADING_6]: node => (
        <Typography variant="h6" gutterBottom>{node.content[0].value}</Typography>
      ),
      [BLOCKS.EMBEDDED_ASSET]: node => (
        <CtflAssetContainer {...node.data.target.sys} />
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const type = typesFromLinks[node.data.target.sys.id]
        return (
          <RenderEmbeddedEntry {...node.data.target} type={type} />
        )
      },
      [BLOCKS.PARAGRAPH]: (node, children) => <div className="rich-paragraph">{children}</div>,
      [INLINES.HYPERLINK]: (node, children) => {
        const expression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
        const regex = new RegExp(expression)
        const url = node.data.uri

        if (url.match(regex)) {
          return <a href={url} target="_blank" rel="noopener noreferrer">{children}</a>
        }

        return <a href={url}>{children}</a>
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const id = node && node.data && node.data.target && node.data.target.sys.id
        const linkProps = renderLink(typesFromLinks[id])

        return <Link {...linkProps}><a>{children}</a></Link>
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        return (
          <RenderInlineEmbeddedEntry {...node.data.target.sys} />
        )
      },
    },
    renderText: (text) => {
      return text.split('\n').reduce((children, textSegment, index) => {
        // eslint-disable-next-line react/no-array-index-key
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
  }

  const content = json ? documentToReactComponents(json, options) : null

  return (
    <RichText>
      {content}
    </RichText>
  )
}

RendererRichText.propTypes = {
  links: PropTypes.shape(),
  json: PropTypes.shape(),
}

RendererRichText.defaultProps = {}

export default RendererRichText
